@import "ui/styles/2-tools/tools.list-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.Promotion {
	position: relative;
	overflow: hidden;
	margin-top: var(--spacing--2xl);
	margin-bottom: var(--spacing--2xl);
	color: var(--color-cta-primary);

	@include mq("sm") {
		display: flex;
		flex-direction: row-reverse;
		margin-bottom: var(--spacing--6xl);
	}
}

.Promotion_infoContainer {
	background-color: transparent;
	background-color: var(--color-supporting-light-blue);
	z-index: 2;
	padding: var(--spacing--lg) var(--spacing--xl) var(--spacing--2xl);

	@include mq("sm") {
		width: 50%;
		padding: var(--spacing--2xl) var(--spacing--2xl) var(--spacing--3xl);
	}
}
.Promotion_imageContainer {
	max-height: 25rem;
	overflow: hidden;
	position: relative;

	@include mq("sm") {
		padding-top: var(--spacing--xl);
		width: 50%;
		max-height: none;
	}
}

.Promotion_mediabox {
	@include mq("md") {
		position: absolute;
	}
}

.Promotion_icon {
	width: 15rem;
	margin-bottom: var(--spacing--md);
}

.Promotion_heading {
	margin-bottom: var(--spacing--lg);

	@include mq("md") {
		margin-bottom: 0;
	}
}

.Promotion_subHeading {
	margin-bottom: var(--spacing--xl);
}

.Promotion_lead {
	font-weight: var(--font-weight--bold);
	margin-bottom: var(--spacing--2xs);
}

.Promotion_subLead {
	margin-bottom: var(--spacing--lg);

	@include mq("md") {
		margin-bottom: var(--spacing--2xl);
	}
}
