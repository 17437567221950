@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.LocalNavigation{
	background-color: var(--color-supporting-sky-blue-20);
	padding: var(--spacing--lg);
	margin-bottom: var(--spacing--lg);
}

.LocalNavigation_item{
	list-style-type: none;
	padding-bottom: var(--spacing--md);
	&:last-of-type{
		padding-bottom: 0;
	}

}

.LocalNavigation_link{
	display: inline-block;
	color: var(--color-cta-primary);
	@include transition {
		transition: transform var(--trans-time--long) var(--ease--dynamic);
	}   
	&:hover{
		transform: translateX(2px);    
	}
}