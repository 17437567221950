@import "ui/styles/2-tools/tools.list-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.CategoryList {
	margin-bottom: var(--spacing--xl);

	@include mq("md") {
		margin-bottom: var(--spacing--6xl);
	}
}

.CategoryList_heading {
	margin-bottom: var(--spacing--lg);
}

.CategoryList_list {
	@include reset-list;
	padding: var(--spacing--lg) 0;
	border-top: var(--border--sm) solid var(--color-border--contrast);
	border-bottom: var(--border--sm) solid var(--color-border--contrast);

	@include mq("md") {
		display: flex;
		flex-wrap: wrap;
	}
}

.CategoryList_item {
	margin-bottom: var(--spacing--md);
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	@include mq("sm") {
		display: block;
		padding-right: var(--spacing--md);
		flex-basis: 33.333333%;
		margin-bottom: var(--spacing--xs);
	}
}

.CategoryList_link {
	@include heading--xl;
	color: var(--color-cta-primary);
	text-transform: uppercase;
	&:hover {
		opacity: 0.8;
	}

	@include mq("sm") {
		@include heading--lg;
	}
}

.CategoryList_number {
	margin-left: var(--spacing--md);
	color: var(--color-text--secondary);
}
