@import "ui/styles/2-tools/tools.media-query.scss";

.Dashboard {
	position: relative;

	@include mq("lg") {
		display: flex;
		flex-direction: row-reverse;
		margin-bottom: var(--spacing--6xl);
	}
}

.Dashboard_image {
	width: 80%;
	margin: 0 auto;
	max-height: 25rem;
	overflow: hidden;
	position: relative;

	@include mq("md") {
		max-height: 35rem;
	}

	@include mq("lg") {
		padding-top: var(--spacing--xl);
		width: 50%;
		max-height: none;
		margin-right: 0;
	}
}

.Dashboard_infoContainer {
	background-color: var(--color-primary-sand);
	padding: var(--spacing--lg);

	@include mq("lg") {
		flex: 1 0 calc(100% / 3 * 2);
		padding: var(--spacing--2xl) var(--spacing--2xl) var(--spacing--3xl);
	}
}

.Dashboard_info {
	@include mq("lg") {
		display: flex;
		justify-content: space-between;
	}
}

.Dashboard_presentation {
	margin-bottom: var(--spacing--lg);
	color: var(--color-primary-dark-blue);
	text-align: center;

	@include mq("lg") {
		text-align: left;
	}
}

.Dashboard_buttonContainer {
	margin-bottom: var(--spacing--lg);
	display: flex;
	flex-wrap: wrap;
	gap: var(--spacing--sm);
	justify-content: space-evenly;

	@include mq("md") {
		flex-wrap: nowrap;
	}
}

.Dashboard_buttonWrapper {
	@include mq("sm") {
		flex-basis: 48%;
	}
}

.Dashboard_button {
	width: 100%;
	background-color: transparent;
	color: var(--color-primary-dark-blue);
}

.Dashboard_links {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 1.5rem 5%;
	margin-top: var(--spacing--lg);
	margin-bottom: var(--spacing--3xl);

	@include mq("md") {
		justify-content: flex-start;
	}
}

.Dashboard_linkContainer {
	flex-basis: 50% - 5%;
	text-align: center;

	@include mq("md") {
		flex-basis: calc((100% - 2 * 5%) / 3);
	}

	@include mq("lg") {
		text-align: left;
	}
}

.Dashboard_link {
	padding-left: 0;
	font-weight: var(--font-weight--normal);
}

.Dashboard_statisticsContainer {
	margin-bottom: var(--spacing--2xl);
	display: grid;
	grid-template-columns: 1fr;

	border-top: var(--border--sm) solid var(--color-primary-gray-40);
	border-bottom: var(--border--sm) solid var(--color-primary-gray-40);

	@include mq("sm") {
		grid-template-columns: repeat(3, 1fr);
	}
}

.Dashboard_statistics {
	padding: var(--spacing--md) var(--spacing--md) var(--spacing--md) 0;
	text-align: center;

	@include mq("lg") {
		flex-basis: 33%;
		text-align: left;
	}
}

.Dashboard_statisticsHeader {
	color: var(--color-primary-dark-blue);
}

.Dashboard_contactHeader {
	color: var(--color-primary-dark-blue);
	text-align: center;

	@include mq("md") {
		margin-bottom: var(--spacing--lg);
		text-align: left;
	}
}

.Dashboard_contactContainer {
	display: flex;
	flex-direction: column;
	gap: var(--spacing--md);
	text-align: center;

	@include mq("md") {
		flex-direction: row;
		align-items: flex-start;
		text-align: left;
	}
}

.Dashboard_contact {
	flex-basis: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: var(--color-primary-dark-blue);

	@include mq("md") {
		flex-direction: row;
		gap: var(--spacing--md);
	}
}

.Dashboard_contactImageContainer {
	margin: var(--spacing--lg) 0;

	@include mq("md") {
		margin: 0;
	}
}

.Dashboard_contactImage {
	width: 100px;
	height: 100px;

	@include mq("md") {
		margin: 0 auto;
	}

	@include mq("lg") {
		margin: 0;
	}
}

.Dashboard_contactDetails {
	margin-bottom: var(--spacing--md);
}

.Dashboard_contactName {
	font-weight: var(--font-weight--bold);
}
