@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.ProductSearchList {
	width: 100%;
	height: 100%;
	position: relative;
}

.ProductSearchList_heading {
	margin-bottom: var(--spacing--xl);
	color: var(--color-primary-dark-blue);

	@include mq("md", "max") {
		font-size: var(--font-size--xl);
	}
}

.ProductSearchList_amount {
	margin-bottom: var(--spacing--lg);
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-between;
	row-gap: var(--spacing--md);

	@include mq("sm") {
		flex-direction: row;
		align-items: center;
	}
}

.ProductSearchListItems {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: var(--spacing--lg);
	margin-bottom: var(--spacing--2xl);
	@include mq("md") {
		grid-template-columns: repeat(3, 1fr);
	}
	@include mq("lg") {
		grid-template-columns: repeat(4, 1fr);
	}
}

.ProductSearchListSorting {
	display: flex;
	align-items: center;
}

.ProductSearchListSortingTitle {
	display: block;

	font-size: var(--font-size--sm);
	line-height: var(--line-height--lg);
	font-weight: var(--font-weight--semibold);
	color: var(--color-text--subtle);
	white-space: nowrap;
	min-width: var(--spacing--2xl);

	margin-right: var(--spacing--sm);

	@include mq("md") {
		margin-right: var(--spacing--md);
		font-weight: var(--font-weight--normal);
	}
}

.ProductSearchListSorting_wrapper {
	position: relative;
	display: flex;
	margin-bottom: var(--spacing--xs);

	@include mq("md") {
		margin-bottom: unset;
	}
}
.ProductSearchListSorting_select {
	// removed default arrow
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: 0;

	position: relative;
	z-index: 5;
	background-color: transparent;
	border: var(--border--sm) solid var(--color-border--normal);
	border-radius: var(--corner-size--sm);
	padding: var(--spacing--sm) var(--spacing--md);
	padding-right: var(--spacing--2xl);

	font-size: var(--font-size--sm);
	color: var(--color-primary-gray-90);

	&::-ms-expand {
		display: none;
	}
}

.ProductSearchListSorting_option {
	background-color: white;
	width: 310px;
	padding: 10px 15px;
	height: 120px;

	font-weight: var(--font-weight--medium);
	font-size: var(--font-size--sm);
	color: var(--color-primary-gray-90);
	text-indent: 0.01px;
}

.ProductSorting_buttonIcon {
	width: 1rem;
	height: 1rem;
	position: absolute;
	right: var(--spacing--md);
	top: 50%;
	transform: translateY(-50%);

	@include transition {
		transition: all var(--trans-time--long);
	}

	&.isOpen {
		transform: translateY(-50%) rotate(180deg);
	}
}
