@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.AccordionNavigation {
	@include mq("sm") {
		display: grid;
		grid-template-columns: minmax(20%, 20rem) 1fr;
		column-gap: var(--spacing--4xl);
		align-items: start;
	}
	margin-bottom: var(--spacing--2xl);
}

.AccordionNavigation_heading{
	margin-bottom: var(--spacing--2xl);
}